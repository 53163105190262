import { colors, weight } from "../../Styles"
import { StyleObject } from "../../types/Types"
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer style={styles.footer}>
			<div style={styles.container} className="footer-container">
				<div style={styles.leftContainer}>
					<div style={styles.footerDuoSection}>
						<div style={styles.footerSection}>
							<span style={styles.footerSectionTitle}>Contact</span>
							<a
								href="mailto:kate.myers@poolstoreco.com"
								style={{ color: "inherit", textDecoration: "inherit" }}
							>
								kate.myers@poolstoreco.com
							</a>
							<a
								href="tel:+12602131600"
								style={{ color: "inherit", textDecoration: "inherit" }}
							>
								(260) 213-1600
							</a>
						</div>
						<div style={styles.footerSection}>
							<span style={styles.footerSectionTitle}>Office Hours</span>
							<span>Monday - Friday</span>
							<span>9 AM - 5 PM EST</span>
						</div>
					</div>
					<div style={styles.footerDuoSection}>
						<address style={styles.footerSection}>
							<span style={styles.footerSectionTitle}>Wabash Location</span>
							<span>1310 Manchester Ave</span>
							<span>Wabash, IN 46992</span>
						</address>
						<address style={styles.footerSection}>
							<span style={styles.footerSectionTitle}>
								North Manchester Location
							</span>
							<span>2931 E 1425 N</span>
							<span>North Manchester, IN 46962</span>
						</address>
					</div>
					<div style={styles.footerDuoSection}>
						<div style={styles.footerSection}>

						</div>
					</div>
				</div>
				<div style={{ ...styles.copyright, flexDirection: 'column' }}>
					<span style={styles.copyrightText}>
						© Copyright The Pool Store 2023
					</span>
					<Link
						to="/privacy-policy"
						style={{ ...styles.footerSection, textDecoration: "inherit" }}
					>
						Privacy Policy
					</Link>
				</div>
			</div>
		</footer>
	)
}

const styles: StyleObject = {
	footer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: colors.primary,
		minHeight: 130,
		width: "100%",
		padding: "0 20px",
	},
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		padding: "0 20px",
		flexWrap: "wrap",
		gap: 25,
	},
	leftContainer: {
		height: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
		padding: "0 0 20px 0",
		flex: 1,
		gap: 25,
	},
	footerSection: {
		justifyContent: "center",
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		color: colors.white,
		fontWeight: weight.light,
		gap: 8,
		fontStyle: "normal",
		marginTop: 20,
	},
	footerDuoSection: {
		justifyContent: "center",
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: 8,
	},
	footerSectionTitle: {
		fontSize: 18,
		fontWeight: weight.regular,
	},
	copyright: {
		height: 100,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
		flex: 1,
	},
	copyrightText: {
		color: colors.white,
		fontWeight: weight.light,
		minWidth: 200,
	},
}

export default Footer
