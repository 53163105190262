import { Route, Routes, useLocation } from "react-router-dom"
import HomePage from "../../pages/Home/Home"
import ImageHeader from "../ImageHeader/ImageHeader"
import Navbar from "../NavBar/NavBar"
import Footer from "../Footer/Footer"
import { StyleObject } from "../../types/Types"
import PoolsPage from "../../pages/Pools/Pools"
import HardscapePage from "../../pages/Hardscape/Hardscape"
import MoreServicesPage from "../../pages/MoreServices/MoreServices"
import FaqsPage from "../../pages/FAQs/Faqs"
import ContactPage from "../../pages/Contact/Contact"
import NotFoundPage from "../../pages/NotFound/NotFound"
import ScrollToTop from "../ScrollToTop/ScrollToTop"
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy"
import FinancingPage from "../../pages/Financing/Financing"

export const Navigation = () => {
	const location = useLocation()
	return (
		<div style={styles.container}>
			<div>
				<ScrollToTop />
				{location.pathname === "/" && (
					<ImageHeader
						tagline="Creating Your Dream Oasis, One Design at a Time."
						description="Unleashing Outdoor Luxury with Artful Designs"
						logo={true}
					/>
				)}
				<Navbar />
				<Routes>
					<Route path="/" Component={HomePage} />
					<Route path="/pools" Component={PoolsPage} />
					<Route path="/hardscape" Component={HardscapePage} />
					<Route path="/more-services" Component={MoreServicesPage} />
					<Route path="/financing" Component={FinancingPage} />
					<Route path="/faqs" Component={FaqsPage} />
					<Route path="/contact" Component={ContactPage} />
					<Route path="/privacy-policy" Component={PrivacyPolicy} />
					<Route path="*" Component={NotFoundPage} />
				</Routes>
			</div>
			<Footer />
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		minHeight: "100vh",
	},
}

export default Navigation
