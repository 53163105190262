import { NavLink } from "react-router-dom"
import { weight, colors } from "../../Styles"
import { StyleObject } from "../../types/Types"

type ImageLinkProps = {
	to: string
	onClick?: () => void
	image: string
}

export const ImageLink = ({ to, onClick, image }: ImageLinkProps) => {
	return (
		<NavLink
			to={to}
			target="_blank"
			rel="noreferrer"
			onClick={onClick}
			style={styles.anchor}
		>
			<div
				style={{ ...styles.container, ...{ backgroundImage: `url(${image})` } }}
			/>
		</NavLink>
	)
}

const styles: StyleObject = {
	container: {
		height: 230,
		width: "100%",
		minWidth: 200,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 16,
		fontWeight: weight.medium,
		color: colors.white,
		backgroundColor: colors.white,
		padding: "0 50px",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		flexGrow: 1,
	},
	anchor: {
		textDecoration: "none",
		display: "flex",
		boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
		flexGrow: 1,
	},
}

export default ImageLink
