import { colors, weight } from "../../Styles"
import { CustomerType, StyleObject } from "../../types/Types"
import Star from "../../assets/icons/star.svg"

type TestimonalProps = {
	name: string
	stars: number
	customerType: CustomerType
	review: string
	className?: string
}

const Testimonial = ({
	name,
	stars,
	customerType,
	review,
	className,
}: TestimonalProps) => {
	const starArray = Array.from({ length: stars }, (_, index) => index)

	const getCustomerType = (type: CustomerType) => {
		switch (type) {
			case "pool":
				return "Pool Customer"
			case "hardscape":
				return "Hardscape Customer"
			default:
				return "Customer"
		}
	}

	return (
		<div style={styles.testimonial} className={className}>
			<span style={styles.review}>{review}</span>
			<div style={styles.bottom}>
				<span style={styles.customerType}>{getCustomerType(customerType)}</span>
				<div style={styles.stars}>
					{starArray.map((_, index) => (
						<img
							key={index}
							src={Star}
							alt="Star"
							style={styles.star}
							loading="lazy"
						/>
					))}
				</div>
				<span style={styles.name}>{name}</span>
			</div>
		</div>
	)
}

const styles: StyleObject = {
	testimonial: {
		backgroundColor: colors.white,
		boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
		width: 250,
		minWidth: 250,
		height: 250,
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		textAlign: "center",
	},
	review: {
		fontSize: 14,
		fontWeight: weight.light,
		display: "block",
		lineHeight: "150%",
		width: "100%",
		overflowX: "auto",
		whiteSpace: "normal",
		padding: "20px 20px 0px 20px",
		overflowY: "hidden",
	},
	bottom: {
		marginBottom: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	name: {
		fontWeight: weight.regular,
		fontSize: 16,
		color: colors.primary,
		display: "block",
		textAlign: "center",
	},
	stars: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: 8,
		gap: 5,
	},
	star: {
		height: 18,
		width: 18,
	},
	customerType: {
		display: "block",
		textAlign: "center",
		color: colors.gray,
		fontSize: 12,
		fontWeight: weight.regular,
		marginBottom: 20,
	},
}

export default Testimonial
