import { Helmet } from 'react-helmet'
import { bigHeader, colors, smallHeader, weight } from "../../Styles"
import SpecialHeader from "../../components/SpecialHeader/SpecialHeader"
import { StyleObject } from "../../types/Types"
import HeaderImage from "../../assets/images/pool-1.webp"
import MessageBox, { EmailType } from "../../components/MessageBox/MessageBox"
import SolidButton from "../../components/SolidButton/SolidButton"
import ImageLink from "../../components/ImageLink/ImageLink"
import PoolOutline1 from "../../assets/images/pool-outline-1.png"
import PoolOutline2 from "../../assets/images/pool-outline-2.png"
import PoolOutline3 from "../../assets/images/pool-outline-3.png"
import PoolPreview1 from "../../assets/images/pool-preview-1.png"
import PoolPreview2 from "../../assets/images/pool-preview-2.png"
import PoolPreview3 from "../../assets/images/pool-preview-3.png"
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel"
import HeaderImage1 from "../../assets/images/pool-8.webp"
import HeaderImage2 from "../../assets/images/pool-9.webp"
import HeaderImage3 from "../../assets/images/pool-10.webp"
import ImageGallery from "../../components/ImageGallery/ImageGallery"
import Button from "../../components/Button/Button"
import { useEffect, useState } from "react"

const PoolsPage = () => {
	const [showShapeModal, setShowShapeModal] = useState(false)
	const [showColorModal, setShowColorModal] = useState(false)

	useEffect(() => {
		if (showColorModal || showShapeModal) {
			document.body.classList.add("hidden-scroll")
		} else {
			document.body.classList.remove("hidden-scroll")
		}
	}, [showColorModal, showShapeModal])

	return (
		<div style={styles.container}>
			<Helmet>
				<title>The Pool Store | Pools</title>
				<meta name="robots" content="index,follow" />
			</Helmet>
			<div style={styles.topContainer}>
				<div style={styles.heroContainer} className="hero-container">
					<div style={styles.heroTextContainer}>
						<SpecialHeader headerText="Pools" />
						<span style={bigHeader}>
							Northern Indiana's Largest Fiberglass Pool Dealer
						</span>
						<span style={smallHeader}>
							At The Pool Store, we are a leading pool and hardscaping company
							dedicated to creating stunning outdoor spaces.
						</span>
					</div>
					<div
						className="hero-image"
						style={{
							...styles.heroImage,
							...{
								backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})`,
							},
						}}
					></div>
				</div>
			</div>
			<div style={styles.quoteContainer}>
				<div style={styles.quoteContent}>
					<SpecialHeader headerText="Immerse Yourself in Luxury" />
					<span style={bigHeader}>Premium Fiberglass Pools</span>
					<span style={styles.quoteText}>
						Discover the ultimate solution in luxury and convenience with our
						premium selection of fiberglass pools. Engineered with
						top-of-the-line materials, our fiberglass pools boast unparalleled
						durability, ensuring a lifetime of enjoyment for you and your
						family. Experience the ease of maintenance as the smooth, non-porous
						surface resists algae and stains, significantly reducing cleaning
						efforts and chemical usage. With quick installation and superior
						strength against environmental factors, our fiberglass pools stand
						the test of time without compromising on quality. Choose from an
						array of shapes, sizes, and colors to create a customized oasis that
						perfectly complements your backyard. Embrace a comfortable and safe
						swimming experience, as the smooth finish of our pools is gentle on
						the skin. Upgrade your outdoor space today with a fiberglass pool
						that not only enhances your lifestyle but also contributes to energy
						efficiency and long-term savings.
					</span>
				</div>
				<div className="message-box-container">
					<MessageBox emailType={EmailType.Pools} />
				</div>
			</div>
			<div
				style={{
					...styles.quoteContainer,
					...{
						backgroundColor: colors.lightGray,
						display: "flex",
						flexDirection: "column",
						alignContent: "center",
						alignItems: "center",
					},
				}}
			>
				<div style={styles.quoteContentExtended}>
					<SpecialHeader headerText="Dive into Affordable Elegance" />
					<span style={bigHeader}>Liner Pools</span>
					<span style={styles.quoteText}>
						Explore the perfect blend of beauty and affordability with our
						stunning selection of liner pools. Designed to cater to your
						aesthetic preferences and your budget, our liner pools offer a
						cost-effective alternative to traditional pool options. Crafted with
						attention to detail, these pools feature a durable vinyl liner that
						adds an exquisite touch to your outdoor oasis. Our liner pools come
						in a variety of shapes and sizes, allowing you to tailor your pool
						to your unique vision. Choose from an array of patterns and colors
						for the vinyl liner, ensuring that your pool complements your
						backyard's style seamlessly. While liner pools may be
						budget-friendly, they don't compromise on quality. We prioritize
						durability and longevity in our liner pool construction. Maintenance
						is a breeze with our liner pools. The smooth surface of the vinyl
						liner not only looks beautiful but also makes cleaning effortless,
						reducing the time and effort required to keep your pool sparkling
						clean. Dive into elegance without breaking the bank. Plus, they are
						quick to install, so you can start enjoying your pool sooner.
						Upgrade your backyard with the charm of a liner pool and experience
						the joy of luxury on a budget. Make the most of your outdoor living
						space while enjoying the beauty and convenience of a liner pool.
					</span>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						width: "100%",
						justifyContent: "center",
						gap: "40px",
						flexWrap: "wrap",
					}}
				>
					<Button text="View Shapes" onClick={() => setShowShapeModal(true)} />
					<Button text="View Colors" onClick={() => setShowColorModal(true)} />
				</div>
				{showShapeModal && (
					<ImageGallery
						showModal={showShapeModal}
						setShowModal={setShowShapeModal}
						title="Liner Pool Shapes"
						gallery="shapes"
					/>
				)}
				{showColorModal && (
					<ImageGallery
						showModal={showColorModal}
						setShowModal={setShowColorModal}
						title="Liner Pool Colors"
						gallery="colors"
					/>
				)}
			</div>
			<div
				style={{
					...styles.poolPreviewContainer,
					...{ backgroundColor: colors.white },
				}}
			>
				<div style={styles.leftPreview} className="left-preview">
					<div className="single-only">
						<ImageLink
							to="https://thursdaypools.com/fiberglass-pool-designs/"
							image={PoolOutline1}
						/>
					</div>
					<SpecialHeader headerText="Thursday Pools" />
					<span style={styles.previewDescription}>
						Thursday Pools has a variety of fiberglass pool designs to meet your
						needs as one of our trusted suppliers.
					</span>
					<SolidButton
						text="View All"
						onClick={() => null}
						to="https://thursdaypools.com/fiberglass-pool-designs/"
					/>
				</div>
				<div style={styles.rightPreview} className="right-preview">
					<ImageLink
						to="https://thursdaypools.com/fiberglass-pool-designs/"
						image={PoolOutline1}
					/>
					<ImageLink
						to="https://thursdaypools.com/fiberglass-pool-designs/"
						image={PoolOutline2}
					/>
					<ImageLink
						to="https://thursdaypools.com/fiberglass-pool-designs/"
						image={PoolOutline3}
					/>
				</div>
			</div>
			<div
				style={{
					...styles.poolPreviewContainer,
					...{ backgroundColor: colors.lightGray },
				}}
			>
				<div style={styles.leftPreview} className="left-preview">
					<div className="single-only">
						<ImageLink
							to="https://leisurepoolsusa.com/fiberglass-swimming-pools/"
							image={PoolPreview1}
						/>
					</div>
					<SpecialHeader headerText="Leisure Pools" />
					<span style={styles.previewDescription}>
						Leisure Pools offers a variety of fiberglass swimming pool shapes
						and sizes to suit all personal styles as one of our trusted
						supplier.
					</span>
					<SolidButton
						text="View All"
						onClick={() => null}
						to="https://leisurepoolsusa.com/fiberglass-swimming-pools/"
					/>
				</div>
				<div style={styles.rightPreview} className="right-preview">
					<ImageLink
						to="https://leisurepoolsusa.com/fiberglass-swimming-pools/"
						image={PoolPreview1}
					/>
					<ImageLink
						to="https://leisurepoolsusa.com/fiberglass-swimming-pools/"
						image={PoolPreview2}
					/>
					<ImageLink
						to="https://leisurepoolsusa.com/fiberglass-swimming-pools/"
						image={PoolPreview3}
					/>
				</div>
			</div>
			<div
				style={{
					...styles.sectionContainer,
					...{ backgroundColor: colors.white },
				}}
			>
				<div style={styles.carouselContainer}>
					<ImageCarousel images={[HeaderImage1, HeaderImage2, HeaderImage3]} />
				</div>
			</div>
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
	},
	heroImage: {
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		height: 400,
		flexGrow: 2,
		display: "flex",
		minWidth: 600,
	},
	heroTextContainer: {
		display: "flex",
		flexBasis: 0,
		flexGrow: 1,
		flexDirection: "column",
		gap: 10,
		minWidth: 250,
	},
	topContainer: {
		width: "100%",
		padding: "50px 20px",
		backgroundColor: colors.lightGray,
		display: "flex",
		justifyContent: "center",
	},
	heroContainer: {
		maxWidth: 1100,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		gap: 50,
		flexWrap: "wrap",
	},
	quoteContainer: {
		width: "100%",
		padding: "50px 20px",
		backgroundColor: colors.white,
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		gap: 60,
	},
	quoteContent: {
		maxWidth: 600,
		padding: "0 20px",
	},
	quoteContentExtended: {
		maxWidth: 1100,
		padding: "0 20px",
	},
	quoteText: {
		color: colors.black,
		fontFamily: "Montserrat",
		fontSize: 16,
		fontStyle: "normal",
		fontWeight: weight.light,
		lineHeight: "170%",
		marginTop: 20,
		display: "inline-block",
	},
	poolPreviewContainer: {
		width: "100%",
		padding: "50px 20px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 50,
	},
	previewDescription: {
		color: colors.black,
		fontFamily: "Montserrat",
		fontSize: 14,
		fontStyle: "normal",
		fontWeight: weight.regular,
		display: "inline-block",
		maxWidth: 320,
		marginBottom: 30,
		lineHeight: "150%",
	},
	rightPreview: {
		display: "flex",
		flexDirection: "row",
		gap: 20,
		flexWrap: "wrap",
		overflow: "hidden",
		height: 250,
		padding: "10px",
		width: "100%",
		maxWidth: 900,
	},
	sectionContainer: {
		backgroundColor: colors.lightGray,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		paddingBottom: 50,
	},
	carouselContainer: {
		display: "flex",
		width: "100%",
		alignSelf: "center",
		justifyContent: "center",
		alignItems: "center",
	},
	leftPreview: {
		minWidth: 200,
	},
}

export default PoolsPage
