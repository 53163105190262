import { CSSProperties } from "react"

export const colors = {
	primary: "#17B5E5",
	white: "#FFFFFF",
	black: "#000000",
	gray: "#666666",
	lightGray: "#F7F7F7",
	red: "#E25B5B",
}

export const size = {
	medium: "16px",
}

export const weight = {
	thin: 100,
	extraLight: 200,
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
}

export const bigHeader: CSSProperties = {
	fontSize: 36,
	fontFamily: "Bebas Neue",
	display: "block",
}

export const smallHeader: CSSProperties = {
	fontSize: 20,
	fontWeight: weight.regular,
	display: "block",
	marginTop: 10,
}
