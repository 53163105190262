import { CSSProperties } from "react"
import { colors, weight } from "../../Styles"
import { StyleObject } from "../../types/Types"
import { NavLink } from "react-router-dom"
import "./SolidButton.css"

type SolidButtonProps = {
	onClick: () => void
	text: string
	to: string
	style?: CSSProperties
}

export const SolidButton = ({ onClick, text, style, to }: SolidButtonProps) => {
	return (
		<NavLink
			to={to}
			target="_blank"
			rel="noreferrer"
			onClick={onClick}
			style={{ ...styles.anchor, ...style }}
		>
			<div className="solidButton" style={styles.container}>
				{text}
			</div>
		</NavLink>
	)
}

const styles: StyleObject = {
	container: {
		height: 40,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 16,
		fontWeight: weight.medium,
		color: colors.white,
		backgroundColor: colors.primary,
		padding: "0 50px",
		textAlign: "center",
	},
	anchor: {
		textDecoration: "none",
		display: "flex",
	},
}

export default SolidButton
