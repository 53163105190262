import { bigHeader, colors, weight } from "../../Styles"
import { StyleObject } from "../../types/Types"
import SpecialHeader from "../SpecialHeader/SpecialHeader"

type ServiceCardProps = {
	specialHeader: string
	header: string
	text: string
	image: string
	textPosition: "left" | "right" | "bottom"
}

export const ServiceCard = ({
	header,
	image,
	specialHeader,
	text,
	textPosition,
}: ServiceCardProps) => {
	return (
		<div style={styles.wrapper}>
			<div
				className="service-container"
				style={{
					...styles.container,
					...{
						flexDirection:
							textPosition === "left"
								? "row"
								: textPosition === "right"
								? "row-reverse"
								: "column-reverse",
					},
				}}
			>
				<div
					className="service-text-container"
					style={
						textPosition === "bottom"
							? styles.textContainerVertical
							: styles.textContainerHorizontal
					}
				>
					<div>
						<SpecialHeader headerText={specialHeader} />
						<div style={bigHeader}>{header}</div>
					</div>
					<div style={styles.reading}>{text}</div>
				</div>
				<div
					className="service-image-container"
					style={{
						...styles.image,
						...{
							backgroundImage: `url(${image})`,
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center",
							height: textPosition === "bottom" ? "100%" : undefined,
						},
					}}
				/>
			</div>
		</div>
	)
}

const styles: StyleObject = {
	wrapper: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	container: {
		display: "flex",
		maxWidth: 1200,
		minHeight: 600,
		width: "100%",
		boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
		backgroundColor: colors.white,
		marginLeft: 50,
		marginRight: 50,
		flexShrink: 1,
	},
	image: {
		width: "100%",
		backgroundColor: "#666666",
		position: "relative",
		flex: 1,
	},
	textContainerHorizontal: {
		width: "100%",
		maxWidth: 420,
		padding: "50px 50px",
	},
	textContainerVertical: {
		width: "100%",
		padding: "50px 50px",
		display: "flex",
		justifyContent: "space-between",
		gap: 50,
	},
	reading: {
		fontSize: 16,
		fontWeight: weight.light,
		lineHeight: "150%",
		marginTop: 20,
		maxWidth: 600,
	},
}

export default ServiceCard
