import { Helmet } from "react-helmet"
import ImageHeader from "../../components/ImageHeader/ImageHeader"
import MessageBox, { EmailType } from "../../components/MessageBox/MessageBox"
import { colors } from "../../Styles"
import { StyleObject } from "../../types/Types"
import PoolBigHouse from "../../assets/images/pool-7.webp"

export const ContactPage = () => {
	return (
		<div style={styles.container}>
			<Helmet>
			<title>The Pool Store | Contact</title>
				<meta
					name="description"
					content="Get in touch with our team to start your outdoor transformation today."
				/>
				<meta
					name="keywords"
					content="boulder outdoors, contact us, pool installation, hardscape installation, outdoor design"
				/>
				<meta name="robots" content="index, nofollow" />
			</Helmet>
			<ImageHeader
				tagline=""
				description=""
				logo={false}
				specialHeaderText=""
				specialHeaderColor={colors.white}
				specialHeaderBottom={10}
				simple
				image={PoolBigHouse}
			/>
			<div style={styles.wrapper}>
				<div style={styles.messageBox} className="message-box">
					<MessageBox emailType={EmailType.ContactUs} large />
				</div>
				<div className="contact-spacer" />
			</div>
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
	},
	wrapper: {
		position: "relative",
		display: "flex",
		justifyContent: "center",
	},
	messageBox: {
		position: "absolute",
		top: -100,
		padding: "0 50px",
		width: "100%",
		maxWidth: 1200,
	},
}

export default ContactPage
