import { NavLink } from "react-router-dom"
import { colors } from "../../Styles"
import { StyleObject } from "../../types/Types"
import LogoWhite from "../../assets/logos/logo-white.png"
import Boulder from "../../assets/logos/boulder.png"

export const MobileMenu = () => {
	return (
		<div style={styles.mobileMenu}>
			<div style={styles.topMobileNav}>
				<NavLink to="/" style={styles.mobileLogoContainer}>
					<img
						src={LogoWhite}
						alt="The Pool Store Logo"
						style={styles.brandLogo}
						height={50}
						loading="lazy"
					/>
				</NavLink>
			</div>
			<div style={styles.mobileLinks}>
				<NavLink
					className="link mobileLink"
					to="/hardscape"
					style={({ isActive }) =>
						isActive ? styles.activeLinkMobile : styles.inactiveLink
					}
				>
					<img src={Boulder} alt="The Pool Store Logo" height={50} />
				</NavLink>
				<NavLink
					className="link mobileLink"
					to="/pools"
					style={({ isActive }) =>
						isActive ? styles.activeLinkMobile : styles.inactiveLink
					}
				>
					Pools
				</NavLink>
				<NavLink
					className="link mobileLink"
					to="/more-services"
					style={({ isActive }) =>
						isActive ? styles.activeLinkMobile : styles.inactiveLink
					}
				>
					More Services
				</NavLink>
				<NavLink
					className="link mobileLink"
					to="/financing"
					style={({ isActive }) =>
						isActive ? styles.activeLinkMobile : styles.inactiveLink
					}
				>
					Financing
				</NavLink>
				<NavLink
					className="link mobileLink"
					to="/contact"
					style={({ isActive }) =>
						isActive ? styles.activeLinkMobile : styles.inactiveLink
					}
				>
					Contact Us
				</NavLink>
				<NavLink
					className="link mobileLink"
					to="/faqs"
					style={({ isActive }) =>
						isActive ? styles.activeLinkMobile : styles.inactiveLink
					}
				>
					FAQs
				</NavLink>
			</div>
		</div>
	)
}

const styles: StyleObject = {
	brandLogo: {
		marginRight: "20px",
	},
	inactiveLink: {},
	activeLink: {
		borderBottom: "2px solid #ffffff",
	},
	activeLinkMobile: {
		color: colors.black,
		borderBottom: "2px solid #000000",
	},
	mobileMenu: {
		position: "absolute",
		top: 0,
		right: 0,
		height: "100%",
		width: "75%",
		backgroundColor: colors.white,
		boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
		cursor: "default",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	topMobileNav: {
		backgroundColor: "#17B5E5",
		width: "100%",
	},
	mobileLogoContainer: {
		display: "flex",
		padding: "50px 0",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	mobileLinks: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		alignItems: "center",
		overflowY: "scroll",
	},
}

export default MobileMenu
