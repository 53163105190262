import { CSSProperties } from "react"
import { colors, weight } from "../../Styles"
import { StyleObject } from "../../types/Types"

type ButtonProps = {
	onClick: () => void
	text: string
	style?: CSSProperties
	className?: string
	maxWidth?: number | string
}

export const Button = ({
	onClick,
	text,
	style,
	className,
	maxWidth = 360,
}: ButtonProps) => {
	const clickEvent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault()
		onClick()
	}

	return (
		<button
			onClick={clickEvent}
			style={{ ...styles.button, ...style, ...{ maxWidth } }}
		>
			<div className={className} style={styles.container}>
				{text}
			</div>
		</button>
	)
}

const styles: StyleObject = {
	container: {
		border: `2px solid ${colors.primary}`,
		height: 60,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 24,
		fontWeight: weight.regular,
		color: colors.primary,
	},
	button: {
		appearance: "none",
		textDecoration: "none",
		width: "100%",
		backgroundColor: "transparent",
		border: "none",
		cursor: "pointer",
	},
}

export default Button
