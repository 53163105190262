import Lottie from "lottie-react"
import { Helmet } from "react-helmet"
import { StyleObject } from "../../types/Types"
import Missing from "../../assets/lottie/missing.json"

const NotFoundPage = () => {
	return (
		<div style={styles.container}>
			<Helmet>
				<title>The Pool Store | Not Found</title>
				<meta
					name="description"
					content="The page you are looking for does not exist. Explore our diverse range of services at The Pool Store."
				/>
				<meta
					name="keywords"
					content="pool services, pool care, pool repair, pool supplies, pool replacement, pool examination, pool cleaning"
				/>
				<meta name="robots" content="noindex" />
			</Helmet>
			<div style={styles.lottieContainer}>
				<Lottie animationData={Missing} loop={true} style={styles.lottie} />
			</div>
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
	},
	lottie: {
		width: "100%",
		maxWidth: 600,
	},
	lottieContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 0,
	},
}

export default NotFoundPage
