import { weight } from "../../Styles"
import { StyleObject } from "../../types/Types"

type FaqItemProps = {
	question: string
	answer: string[]
	backgroundColor: string
}

export const FaqItem = ({
	answer,
	question,
	backgroundColor,
}: FaqItemProps) => {
	return (
		<div
			style={{ ...styles.container, ...{ backgroundColor: backgroundColor } }}
		>
			<div style={styles.wrapper} className="faq-wrapper">
				<div style={styles.question}>{question}</div>
				<div style={styles.answer}>
					{answer.map((string, index) => (
						<p key={index}>{string}</p>
					))}
				</div>
			</div>
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		padding: "20px 20px",
		justifyContent: "center",
	},
	wrapper: {
		maxWidth: 1200,
		width: "100%",
		flexDirection: "row",
		display: "flex",
		gap: 50,
	},
	question: {
		width: "100%",
		maxWidth: 320,
		fontFamily: "Bebas Neue",
		fontSize: 24,
	},
	answer: {
		fontSize: 16,
		fontWeight: weight.light,
		lineHeight: "150%",
		maxWidth: 600,
		gap: 20,
		display: "flex",
		flexDirection: "column",
	},
}

export default FaqItem
