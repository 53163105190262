import { NavLink } from "react-router-dom"
import { useEffect, useState } from "react"
import { useMediaQuery } from "../../hooks/useMediaQuery"
import { colors } from "../../Styles"
import { StyleObject } from "../../types/Types"
import LogoWhite from "../../assets/logos/logo-white.png"
import Boulder from "../../assets/logos/boulder.png"
import Facebook from "../../assets/icons/facebook.svg"
import Phone from "../../assets/icons/phone.svg"
import Instagram from "../../assets/icons/instagram.svg"
import MobileIcon from "../MobileIcon/MobileIcon"
import MobileMenu from "../MobileMenu/MobileMenu"
import "./NavBar.css"

const Navbar = () => {
	const mobileNav = useMediaQuery("(max-width: 1025px)")
	const [menuOpen, setMenuOpen] = useState(false)

	useEffect(() => {
		if (!mobileNav) {
			setMenuOpen(false)
		}
	}, [mobileNav])

	return (
		<nav style={styles.nav}>
			<div style={mobileNav ? styles.mobileContainer : styles.desktopContainer}>
				<div style={styles.linkContainer}>
					<div style={styles.imageLink}>
						<NavLink to="/">
							<img
								src={LogoWhite}
								alt="The Pool Store Logo"
								style={styles.brandLogo}
								height={50}
							/>
						</NavLink>
					</div>
					{!mobileNav && (
						<div style={styles.textLinks}>
							<NavLink
								className="link"
								to="/hardscape"
								style={({ isActive }) =>
									isActive ? styles.inactiveLink : styles.inactiveLink
								}
							>
								<img src={Boulder} alt="The Pool Store Logo" height={50} />
							</NavLink>
							<NavLink
								className="link"
								to="/pools"
								style={({ isActive }) =>
									isActive ? styles.activeLink : styles.inactiveLink
								}
							>
								Pools
							</NavLink>
							<NavLink
								className="link"
								to="/more-services"
								style={({ isActive }) =>
									isActive ? styles.activeLink : styles.inactiveLink
								}
							>
								More Services
							</NavLink>
							<NavLink
								className="link"
								to="/financing"
								style={({ isActive }) =>
									isActive ? styles.activeLink : styles.inactiveLink
								}
							>
								Financing
							</NavLink>
							<NavLink
								className="link"
								to="/contact"
								style={({ isActive }) =>
									isActive ? styles.activeLink : styles.inactiveLink
								}
							>
								Contact Us
							</NavLink>
							<NavLink
								className="link"
								to="/faqs"
								style={({ isActive }) =>
									isActive ? styles.activeLink : styles.inactiveLink
								}
							>
								FAQs
							</NavLink>
						</div>
					)}
				</div>
				<div style={mobileNav ? styles.mobileView : undefined}>
					<div
						style={{
							...styles.socials,
							...(mobileNav ? { marginLeft: 20 } : undefined),
						}}
					>
						<div style={styles.socialContainer} className="facebook">
							<NavLink
								to="https://www.facebook.com/ThePoolStore1600/"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={Facebook}
									alt="Facebook Logo"
									height={20}
									width={20}
								/>
							</NavLink>
						</div>
						<div style={styles.socialContainer} className="instagram">
							<NavLink
								to="https://www.instagram.com/thepoolstore/"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={Instagram}
									alt="Instagram Logo"
									height={20}
									width={20}
								/>
							</NavLink>
						</div>
						<div style={styles.socialContainer}>
							<a href="tel:+12602131600">
								<img src={Phone} alt="Phone Logo" height={20} width={20} />
							</a>
						</div>
					</div>
					{mobileNav && (
						<MobileIcon onClick={() => setMenuOpen((state) => !state)} />
					)}
				</div>
			</div>
			<div
				style={styles.mobileBackdrop}
				onClick={() => setMenuOpen(false)}
				className={`mobile-menu ${menuOpen ? "open" : ""}`}
			>
				<MobileMenu />
			</div>
		</nav>
	)
}

const styles: StyleObject = {
	nav: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: colors.primary,
		height: "100px",
		position: "sticky",
		top: 0,
		zIndex: 10,
		boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
	},
	desktopContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		padding: "0 20px",
	},
	mobileContainer: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		padding: "0 20px",
	},
	linkContainer: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	inactiveLink: {},
	activeLink: {
		borderBottom: "2px solid #ffffff",
	},
	activeLinkMobile: {
		color: colors.black,
		borderBottom: "2px solid #000000",
	},
	imageLink: {
		borderRightWidth: 2,
		borderRightColor: colors.white,
		borderRightStyle: "solid",
		height: 100,
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
	},
	brandLogo: {
		marginRight: "20px",
	},
	textLinks: {
		marginLeft: 20,
		alignItems: "center",
		display: "flex",
	},
	socials: {
		display: "flex",
		gap: 20,
	},
	socialContainer: {
		borderRadius: "50%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 40,
		width: 40,
		fontSize: 0,
		borderWidth: 2,
		borderStyle: "solid",
		borderColor: colors.white,
	},
	mobileView: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flex: 1,
	},
	mobileBackdrop: {
		position: "fixed",
		height: "100%",
		width: "100%",
		top: 0,
		right: 0,
		cursor: "pointer",
	},
}

export default Navbar
