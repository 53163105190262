import { Helmet } from "react-helmet"
import { colors } from "../../Styles"
import { StyleObject } from "../../types/Types"
import ImageHeader from "../../components/ImageHeader/ImageHeader"
import ServiceCard from "../../components/ServiceCard/ServiceCard"
import WaterCloseup from "../../assets/images/pool-2.webp"
import BarnPool from "../../assets/images/pool-3.webp"
import BarnPoolTop from "../../assets/images/pool-4.webp"
import Slide from "../../assets/images/pool-6.webp"
import GrassTile from "../../assets/images/hardscape-8.webp"
import Gazeebo from "../../assets/images/hardscape-9.webp"

const MoreServicesPage = () => {
	return (
		<div style={styles.container}>
			<Helmet>
				<title>The Pool Store | More Services</title>
				<meta
					name="description"
					content="Explore our diverse range of services, where your dreams come to life. Unveiling our comprehensive array of services."
				/>
				<meta
					name="keywords"
					content="pool services, pool care, pool repair, pool supplies, pool replacement, pool examination, pool cleaning"
				/>
				<meta name="robots" content="index, follow" />
			</Helmet>
			<ImageHeader
				tagline="Explore Our Diverse Range of Services"
				description="Where Your Dreams Come to Life! Unveiling Our Comprehensive Array of Services"
				logo={false}
				specialHeaderText="Other Services"
				specialHeaderColor={colors.white}
				specialHeaderBottom={10}
				image={BarnPool}
			/>
			<div style={styles.cardContainer}>
				<ServiceCard
					specialHeader={"Care"}
					header={"Weekly cleaning and chemical maintenance"}
					text={
						"Enhance pool ownership with Weekly Cleaning and Chemical Maintenance. Our team maintains a pristine haven through debris removal, cleaning, and chemical treatments. Meticulous testing guarantees inviting waters, while professional care extends equipment longevity. Focus on cherished moments in a sparkling oasis."
					}
					image={Slide}
					textPosition="left"
				/>
				<ServiceCard
					specialHeader={"Repair"}
					header={"In ground pool and equipment repairs"}
					text={
						"Bring new life to your in-ground pool and equipment with our expert repair services. From minor fixes to major overhauls, our skilled technicians have the expertise to address any issue, ensuring your pool operates at peak performance. Don't let equipment malfunctions disrupt your swimming oasis - trust us to diagnose, repair, and restore, so you can dive back into a trouble-free and enjoyable pool experience."
					}
					image={GrassTile}
					textPosition="right"
				/>
				<ServiceCard
					specialHeader={"Supplies"}
					header={"Chemical and Pool Supply Sales"}
					text={
						"Discover essential pool supplies with our comprehensive Chemical and Pool Supply Sales. We offer a wide range of high-quality products designed to maintain your pool's health, cleanliness, and longevity. Whether it's sanitizers, pH balancers, or maintenance products, our selection ensures your pool is equipped for optimal care. Trust our offerings to help you maintain a pristine pool environment for year-round relaxation and enjoyment."
					}
					image={BarnPoolTop}
					textPosition="left"
				/>
				<ServiceCard
					specialHeader={"Replace"}
					header={"Liner Replacements"}
					text={
						"Renew your pool's appeal with our professional Liner Replacement service. Over time, wear and tear can impact both appearance and function. Our skilled team excels in installing high-quality liners, revitalizing aesthetics, and enhancing durability. Choose from various styles for a customized, inviting space. With meticulous attention to detail, we bring excellence to your pool's makeover."
					}
					image={Gazeebo}
					textPosition="right"
				/>
				<ServiceCard
					specialHeader={"Examine"}
					header={"Water Testing"}
					text={
						"Ensure your pool's health and clarity with our Water Testing Services. Our advanced testing methods provide accurate insights into your water's chemical balance, enabling us to recommend precise adjustments for optimal safety and enjoyment. Trust our expertise to maintain crystal-clear waters, allowing you to dive into a worry-free swimming experience that aligns perfectly with your standards."
					}
					image={WaterCloseup}
					textPosition="bottom"
				/>
			</div>
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
	},
	cardContainer: {
		display: "flex",
		flexDirection: "column",
		gap: 20,
		marginTop: 50,
		marginBottom: 100,
	},
}

export default MoreServicesPage
