import { ChangeEvent, ChangeEventHandler, useRef, useState } from "react"
import { colors } from "../../Styles"
import { StyleObject } from "../../types/Types"
import Button from "../Button/Button"
import SpecialHeader from "../SpecialHeader/SpecialHeader"
import Lottie from "lottie-react"
import Success from "../../assets/lottie/success.json"
import Fail from "../../assets/lottie/fail.json"
import Sending from "../../assets/lottie/sending.json"
import "./MessageBox.css"

export enum EmailType {
	Pools = "pools",
	Hardscape = "hardscape",
	ContactUs = "contact-us",
}

type MessageBoxProps = {
	large?: boolean
	emailType: EmailType
}

type FormData = {
	name: string
	phoneNumber: string
	zip: string
	message: string
	emailAddress: string
	emailType: EmailType
}

export const MessageBox = ({ large, emailType }: MessageBoxProps) => {
	const [phoneNumber, setPhoneNumber] = useState("")
	const [emailAddress, setEmailAddress] = useState("")
	const [zip, setZip] = useState("")
	const [name, setName] = useState("")
	const [message, setMessage] = useState("")
	const [nameError, setNameError] = useState(false)
	const [zipError, setZipError] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [messageError, setMessageError] = useState(false)
	const [messageSent, setMessageSent] = useState(false)
	const [messageFailed, setMessageFailed] = useState(false)
	const [messageSending, setMessageSending] = useState(false)
	const [errorCode, setErrorCode] = useState<string | null>(null)
	const inputRef = useRef<HTMLInputElement | null>(null)

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value
		const cleanedValue = inputValue.replace(/\D/g, "")
		if (cleanedValue.length <= 5) {
			setZip(cleanedValue)
		} else if (cleanedValue.length > 5) {
			return
		}
		if (cleanedValue.length === 5) {
			setZipError(false)
		} else {
			setZipError(true)
		}
	}

	const handleMessageChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
		const inputValue = e.target.value
		if (inputValue) {
			setMessageError(false)
		} else {
			setMessageError(true)
		}
		setMessage(inputValue)
	}

	const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value
		if (inputValue.length < 2) {
			setNameError(true)
		} else {
			setNameError(false)
		}
		setName(inputValue)
	}

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
		if (!emailPattern.test(inputValue)) {
			setEmailError(true)
		} else {
			setEmailError(false)
		}
		setEmailAddress(inputValue)
	}

	const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputPhoneNumber = e.target.value.replace(/[() -]/g, "")
		if (inputPhoneNumber.length === 10) {
			const formattedNumber = `(${inputPhoneNumber.slice(
				0,
				3
			)}) ${inputPhoneNumber.slice(3, 6)}-${inputPhoneNumber.slice(6)}`
			setPhoneNumber(formattedNumber)
			setPhoneError(false)
		} else if (inputPhoneNumber.length > 10) {
			return
		} else {
			setPhoneNumber(inputPhoneNumber)
			setPhoneError(true)
		}
	}

	const validateAndSubmit = () => {
		const phoneValid = phoneNumber.length >= 10
		if (!phoneValid) {
			setPhoneError(true)
		}
		const zipValid = zip.length === 5
		if (!zipValid) {
			setZipError(true)
		}
		const nameValid = name.length >= 2
		if (!nameValid) {
			setNameError(true)
		}
		const messageValid = message.length
		if (!phoneValid) {
			setMessageError(true)
		}
		if (phoneValid && zipValid && nameValid && messageValid) {
			setPhoneError(false)
			setZipError(false)
			setNameError(false)
			setMessageError(false)
			setMessageSending(true)
			postData({
				emailAddress,
				emailType,
				message,
				name,
				phoneNumber,
				zip,
			})
		}
	}

	const resetForm = () => {
		setName("")
		setEmailAddress("")
		setPhoneNumber("")
		setZip("")
		setMessageSent(false)
	}

	const retryForm = () => {
		setMessageFailed(false)
		setErrorCode(null)
	}

	const postData = (formData: FormData) => {
		fetch("https://poolstoreco.com/send-email.php", {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		})
			.then((response) => {
				console.log("[RESPONSE]:", response)
				if (!response.ok) {
					setErrorCode(response.status.toString())
					throw new Error("Network response was not ok!")
				} else {
					setMessageSent(true)
				}
				return response.json()
			})
			.then((data) => {
				console.log("[DATA]:", data)
			})
			.catch((error) => {
				console.error("[ERROR]:", error)
				setMessageFailed(true)
			})
			.finally(() => {
				setMessageSending(false)
			})
	}

	return (
		<div style={styles.wrapper} className="message-wrapper">
			<div
				className="message-container"
				style={{
					...styles.container,
					...{
						maxWidth: large ? 1200 : 500,
						maxHeight: large ? 800 : 600,
						marginBottom: large ? 50 : 20,
					},
				}}
			>
				{large && <div style={styles.largeTitle}>Contact Us</div>}
				<div style={styles.titleContainer}>
					<span style={styles.title}>
						{messageSending
							? ""
							: messageSent
								? "Message Sent"
								: messageFailed
									? "Failed to Send"
									: large
										? "Send Us a Message"
										: "Request a Quote"}
					</span>
				</div>
				{messageSent || messageFailed || messageSending ? (
					<div>
						<div style={styles.thankYouText}>
							{messageSending
								? "Sending..."
								: messageSent
									? "Thank You!"
									: `${errorCode} Error`}
						</div>
						<div style={styles.lottieContainer}>
							<Lottie
								animationData={
									messageSending ? Sending : messageSent ? Success : Fail
								}
								loop={messageSending}
								style={styles.lottie}
							/>
						</div>
						<div style={styles.messageSentText}>
							{messageSending
								? "Please wait while your message is being delivered."
								: messageSent
									? "We'll be in touch shortly!"
									: "We could not process your submission, please try again."}
						</div>
					</div>
				) : (
					<form>
						<input
							ref={inputRef}
							type="text"
							name="name"
							autoComplete="name"
							style={{
								...styles.messageBoxInput,
								...(nameError ? styles.errorInput : {}),
							}}
							placeholder="Name"
							minLength={2}
							maxLength={50}
							value={name}
							onChange={handleNameChange}
						/>
						{nameError && (
							<div style={styles.errorMessage}>
								Your name should be at least 2 characters.
							</div>
						)}
						<input
							type="text"
							name="email"
							autoComplete="email"
							style={{
								...styles.messageBoxInput,
								...(emailError ? styles.errorInput : {}),
							}}
							placeholder="Email"
							minLength={2}
							maxLength={50}
							value={emailAddress}
							onChange={handleEmailChange}
						/>
						{emailError && (
							<div style={styles.errorMessage}>
								Your email address is invalid.
							</div>
						)}
						<input
							type="tel"
							name="phone"
							autoComplete="tel"
							style={{
								...styles.messageBoxInput,
								...(phoneError ? styles.errorInput : {}),
							}}
							placeholder="Phone Number"
							value={phoneNumber}
							onChange={handlePhoneChange}
						/>
						{phoneError && (
							<span style={styles.errorMessage}>
								Please enter your 10-digit phone number.
							</span>
						)}
						<input
							type="number"
							name="zip"
							autoComplete="postal-code"
							style={{
								...styles.messageBoxInput,
								...(zipError ? styles.errorInput : {}),
							}}
							placeholder="Zip Code"
							maxLength={5}
							value={zip}
							onChange={handleZipChange}
						/>
						{zipError && (
							<div style={styles.errorMessage}>
								Your zip code should be a 5-digit number.
							</div>
						)}
						<textarea
							name="message"
							style={{
								...styles.messageBoxInput,
								...{ height: 100 },
								...(messageError ? styles.errorInput : {}),
							}}
							placeholder="Message"
							value={message}
							onChange={handleMessageChange}
						/>
						{messageError && (
							<div style={styles.errorMessage}>A message is required.</div>
						)}
					</form>
				)}
				<div style={styles.buttonContainer}>
					<Button
						maxWidth={"100%"}
						className="button"
						onClick={
							messageSending
								? () => null
								: messageSent
									? resetForm
									: messageFailed
										? retryForm
										: validateAndSubmit
						}
						style={
							messageSending ? { opacity: 0, cursor: "default" } : undefined
						}
						text={
							messageSent ? "New Message" : messageFailed ? "Try Again" : "Send"
						}
					/>
				</div>
			</div>
			{large && (
				<div style={styles.moreInfo} className="more-info">
					<div style={styles.infoSection}>
						<SpecialHeader paddingBottom={10} headerText="Phone" />
						<div style={styles.infoText}>(260) 213-1600</div>
					</div>
					<div style={styles.infoSection}>
						<SpecialHeader paddingBottom={10} headerText="Email" />
						<div style={styles.infoText}>kenz.harness@poolstoreco.com</div>
					</div>
					<div style={styles.infoSection}>
						<SpecialHeader
							paddingBottom={10}
							headerText="North Manchester Address"
						/>
						<div style={styles.infoText}>2931 E 1425 N</div>
						<div style={styles.infoText}>North Manchester, IN 46962</div>
					</div>
					<div style={styles.infoSection}>
						<SpecialHeader paddingBottom={10} headerText="Wabash Address" />
						<div style={styles.infoText}>1310 Manchester Ave</div>
						<div style={styles.infoText}>Wabash, IN 46992</div>
					</div>
				</div>
			)}
		</div>
	)
}

const styles: StyleObject = {
	largeTitle: {
		fontSize: 28,
		fontFamily: "Bebas Neue",
		textTransform: "uppercase",
		color: colors.black,
		marginBottom: 60,
		marginTop: 20,
	},
	title: {
		fontSize: 20,
		fontFamily: "Bebas Neue",
		textTransform: "uppercase",
		color: colors.black,
	},
	titleContainer: {
		width: "100%",
		justifyContent: "center",
		display: "flex",
	},
	name: {
		fontSize: 16,
		fontFamily: "Bebas Neue",
		color: colors.gray,
	},
	phoneNumber: {
		fontSize: 16,
		fontFamily: "Bebas Neue",
		color: colors.gray,
	},
	address: {
		fontSize: 16,
		fontFamily: "Bebas Neue",
		color: colors.gray,
	},
	message: {
		fontSize: 16,
		fontFamily: "Bebas Neue",
		color: colors.gray,
	},
	messageBoxInput: {
		fontSize: 16,
		fontFamily: "Montserrat",
		color: colors.gray,
		border: "none",
		borderBottom: `2px solid ${colors.primary}`,
		width: "100%",
		maxWidth: "100%",
		lineHeight: 2,
		marginTop: 30,
		resize: "none",
	},
	errorInput: { borderBottom: `2px solid ${colors.red}` },
	buttonContainer: {
		marginTop: 50,
	},
	container: {
		width: "100%",
		padding: 25,
		display: "flex",
		flexDirection: "column",
	},
	errorMessage: {
		fontFamily: "Montserrat",
		fontSize: 14,
		color: colors.red,
	},
	moreInfo: {
		maxWidth: 420,
		marginTop: 140,
		display: "flex",
		flexDirection: "column",
		gap: 50,
		width: "100%",
	},
	infoText: {
		fontSize: 20,
		fontFamily: "Bebas Neue",
		textTransform: "uppercase",
		color: colors.black,
	},
	thankYouText: {
		fontSize: 36,
		fontFamily: "Bebas Neue",
		textTransform: "uppercase",
		color: colors.black,
		textAlign: "center",
		marginTop: 50,
	},
	messageSentText: {
		fontSize: 21,
		fontFamily: "Bebas Neue",
		textTransform: "uppercase",
		color: colors.gray,
		textAlign: "center",
		marginTop: 25,
	},
	infoSection: {
		display: "flex",
		flexDirection: "column",
		width: 200,
	},
	wrapper: {
		flexDirection: "row",
		display: "flex",
		boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
		backgroundColor: colors.white,
		gap: 50,
	},
	lottie: {
		width: 200,
	},
	lottieContainer: {
		display: "flex",
		justifyContent: "center",
		marginTop: 0,
	},
}

export default MessageBox
