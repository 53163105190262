import { Helmet } from "react-helmet"
import { bigHeader, colors, weight } from "../../Styles"
import SpecialHeader from "../../components/SpecialHeader/SpecialHeader"
import { StyleObject } from "../../types/Types"
import HeaderImage from "../../assets/images/hardscape-7.webp"
import MessageBox, { EmailType } from "../../components/MessageBox/MessageBox"
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel"
import Hardscape1 from "../../assets/images/hardscape-1.webp"
import Hardscape2 from "../../assets/images/hardscape-2.webp"
import Hardscape3 from "../../assets/images/hardscape-3.webp"
import Hardscape4 from "../../assets/images/hardscape-4.webp"
import Hardscape5 from "../../assets/images/hardscape-5.webp"
import Boulder from "../../assets/logos/boulder.png"

const HardscapePage = () => {
	return (
		<div style={styles.container}>
			<Helmet>
				<title>Boulder Outdoors</title>
				<meta
					name="description"
					content="Experience the pinnacle of hardscaping excellence with our services that outshine the competition."
				/>
				<meta
					name="keywords"
					content="boulder outdoors, hardscape, paver patios, fire pits, retaining walls, 3D patio and landscape design, landscape installation"
				/>
				<meta name = "robots" content = "index, follow" />
			</Helmet>
			<div style={styles.topContainer} className="top-container">
				<div style={styles.heroContainer} className="hero-container">
					<div
						style={{
							...styles.heroImage,
							...{
								backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})`,
							},
						}}
					>
						<div style={styles.block} className="block">
							<div style={styles.line} />
							<div style={styles.blockText}>Hardscape</div>
							<div style={styles.line} />
						</div>
					</div>
				</div>
				<div style={styles.imageContainer}>
					<img src={Boulder} height={100} className="boulder-image" alt="Boulder Outdoors" />
				</div>
				<div style={styles.columnContainer}>
					<div style={styles.column}>
						<div style={styles.columnTitle}>
							Paver Patios, Fire Pits, and Retaining Walls
						</div>
						<div style={styles.columnLine} />
						<div style={styles.columnText}>
							Discover the artistry of outdoor transformation with our range of
							hardscaping features. Paver patios offer both elegance and
							utility, creating the perfect space for leisure. Fire pits fuse
							warmth with gathering spots, while retaining walls masterfully
							reshape landscapes, harmonizing beauty and function seamlessly.
						</div>
					</div>
					<div style={styles.column}>
						<div style={styles.columnTitle}>3D Patio and Landscape Design</div>
						<div style={styles.columnLine} />
						<div style={styles.columnText}>
							Embark on a visual journey with our 3D Patio and Landscape Design
							service. Witness your outdoor dreams materialize as we transform
							concepts into lifelike renderings, granting you the ability to
							explore and refine your envisioned space before it becomes
							reality. Experience the thrill of shaping every detail and angle,
							ensuring your patio and landscape align perfectly with your
							imagination.
						</div>
					</div>
					<div style={styles.column}>
						<div style={styles.columnTitle}>Landscape Installation</div>
						<div style={styles.columnLine} />
						<div style={styles.columnText}>
							Experience seamless transition from vision to reality with our
							Landscape Installation service. Our skilled team brings your
							meticulously planned outdoor oasis to life, ensuring that every
							plant is perfectly placed and every element is expertly
							integrated. Let us handle the intricate work, while you watch your
							landscape flourish into a vibrant and harmonious masterpiece.
						</div>
					</div>
				</div>
			</div>
			<div style={styles.quoteContainer}>
				<div style={styles.quoteContent}>
					<SpecialHeader headerText="Elevate Your Outdoor Space" />
					<span style={bigHeader}>Unrivaled Hardscaping Excellence</span>
					<span style={styles.quoteText}>
						Experience the pinnacle of hardscaping excellence with our services
						that outshine the competition. What sets us apart is our unwavering
						dedication to impeccable craftsmanship and meticulous attention to
						detail. Every hardscaping project we undertake is a testament to our
						commitment to quality, ensuring not only stunning aesthetics but
						also enduring functionality. Unlike others, we prioritize a
						personalized approach, working closely with you to bring your unique
						vision to life, from initial concept to the final masterpiece.
						Drawing from our extensive experience, we fuse traditional artistry
						with innovative techniques, resulting in hardscapes that seamlessly
						blend timeless elegance with contemporary flair. Even after the
						project is completed, our relationship continues through
						comprehensive support and maintenance, solidifying our promise of
						hardscaping solutions that stand the test of time while elevating
						your outdoor space to unmatched levels of distinction.
					</span>
				</div>
				<div className="message-box-container">
					<MessageBox emailType={EmailType.Hardscape} />
				</div>
			</div>
			<div style={styles.sectionContainer}>
				<div style={styles.carouselContainer}>
					<ImageCarousel
						images={[
							Hardscape1,
							Hardscape2,
							Hardscape3,
							Hardscape4,
							Hardscape5,
						]}
					/>
				</div>
			</div>
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
	},
	heroImage: {
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		height: 400,
		width: "90%",
		position: "relative",
	},
	topContainer: {
		width: "100%",
		padding: "50px 20px",
		backgroundColor: colors.lightGray,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	heroContainer: {
		maxWidth: 1100,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		gap: 50,
		paddingLeft: 20,
		paddingRight: 20,
		marginBottom: 20,
	},
	block: {
		height: 170,
		width: 250,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.primary,
		position: "absolute",
		bottom: -20,
		left: -20,
		gap: 20,
	},
	blockText: {
		color: colors.white,
		textTransform: "uppercase",
		fontWeight: weight.semiBold,
	},
	line: {
		width: 55,
		height: 2,
		backgroundColor: colors.white,
	},
	quoteContainer: {
		width: "100%",
		padding: "50px 20px",
		backgroundColor: colors.white,
		display: "flex",
		justifyContent: "center",
		gap: 60,
		flexWrap: "wrap",
	},
	quoteContent: {
		maxWidth: 450,
	},
	quoteText: {
		color: colors.black,
		fontFamily: "Montserrat",
		fontSize: 16,
		fontStyle: "normal",
		fontWeight: weight.light,
		lineHeight: "170%",
		marginTop: 20,
		display: "inline-block",
	},
	sectionContainer: {
		backgroundColor: colors.lightGray,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		paddingBottom: 50,
	},
	carouselContainer: {
		display: "flex",
		width: "100%",
		alignSelf: "center",
		justifyContent: "center",
		alignItems: "center",
	},
	columnContainer: {
		display: "flex",
		flexDirection: "row",
		gap: 30,
		paddingRight: 20,
		paddingLeft: 20,
		marginTop: 50,
		flexWrap: "wrap",
		maxWidth: 1000,
	},
	column: {
		flex: 1,
		minWidth: 220,
	},
	columnTitle: {
		color: colors.primary,
		textTransform: "uppercase",
		letterSpacing: -0.8,
		maxWidth: 220,
		lineHeight: 1.5,
	},
	columnText: {
		color: colors.black,
		letterSpacing: -0.8,
		lineHeight: 1.5,
		fontWeight: weight.regular,
		fontSize: 14,
	},
	columnLine: {
		width: 60,
		height: 2,
		backgroundColor: colors.primary,
		marginTop: 5,
		marginBottom: 20,
	},
	imageContainer: {
		marginTop: 50,
	},
}

export default HardscapePage
