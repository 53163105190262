import { Helmet } from "react-helmet"
import FaqItem from "../../components/FaqItem/FaqItem"
import ImageHeader from "../../components/ImageHeader/ImageHeader"
import { colors } from "../../Styles"
import { StyleObject } from "../../types/Types"
import PoolBasketball from "../../assets/images/pool-5.webp"

type FaqList = {
	question: string
	answer: string[]
}

export const FaqsPage = () => {
	const faqList: FaqList[] = [
		{
			question: "How much does it cost to install a pool or hardscape?",
			answer: [
				"Basic pool packages start around $85,000. This includes pool, excavation, plumbing, electrical, pump, filter, heater, lighting, automatic pool cover, fill water, concrete deck (approx 4' perimeter)",
				"Hardscape cost will vary widely depending on project.",
			],
		},
		{
			question: "What types of pools do you offer?",
			answer: ["Fiberglass, Concrete, and Liner."],
		},
		{
			question: "Can you help with pool and hardscape design?",
			answer: [
				"Yes, we offer 3D designs to help you visualize your pool/hardscape project.",
			],
		},
		{
			question: "How long does the installation process take?",
			answer: [
				"Pool installation takes 2-3 weeks, Hardscape will vary widely on project.",
			],
		},
		{
			question: "Do you handle permits and regulations?",
			answer: [
				"We will handle permitting for a fee, fees will vary depending on county you live in. Otherwise homeowner can handle permitting if they choose.",
			],
		},
		{
			question: "What maintenance is required for pools and hardscapes?",
			answer: [
				"With the installation of a pool or hardscape a packet will be given at completion of install that gives detailed instructions for care.",
			],
		},
		{
			question: "What warranties do you offer?",
			answer: [
				"All warranties are offered through the manufacturer. Thursday Pools, Leisure Pools, Pentair and Automatic Pool Covers Inc.",
			],
		},
		{
			question:
				"Can you incorporate additional features like waterfalls, lighting, or fire pits?",
			answer: ["Absolutely!"],
		},
		{
			question:
				"Can you work with existing landscaping or do I need to start from scratch?",
			answer: ["Of course! We can meet you at your home to discuss options."],
		},
		{
			question: "How do you handle drainage and water management?",
			answer: [
				"Our excavation team expertly grades your yard away from your pool to optimize water drainage. If needed further work can be done, such as installing a tile, to manage any water concerns.",
			],
		},
		{
			question: "How do I get started with the process?",
			answer: [
				"Call 260-213-1600 or email kenz.harness@poolstoreco.com to discuss options and schedule a quote.",
			],
		},
	]

	return (
		<div style={styles.container}>
			<Helmet>
				<title>The Pool Store | FAQs</title>
				<meta
					name="description"
					content="Frequently asked questions about pool and hardscape installation and maintenance."
				/>
				<meta name="robots" content="index, nofollow" />
			</Helmet>
			<ImageHeader
				tagline=""
				description=""
				logo={false}
				specialHeaderText="FAQs"
				specialHeaderColor={colors.white}
				specialHeaderBottom={10}
				simple
				image={PoolBasketball}
			/>
			<div style={styles.faqsContainer}>
				{faqList.map((faq, index) => (
					<FaqItem
						key={index}
						question={faq.question}
						answer={faq.answer}
						backgroundColor={index % 2 === 0 ? colors.white : colors.lightGray}
					/>
				))}
			</div>
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
	},
	faqsContainer: {
		display: "flex",
		flexDirection: "column",
		marginTop: 50,
		marginBottom: 100,
	},
}

export default FaqsPage
