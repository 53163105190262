import React from "react"
import Button from "../Button/Button"
import { bigHeader, colors, weight } from "../../Styles"
import { StyleObject } from "../../types/Types"
import img01 from "../../assets/images/liner-shapes/auto-cover-kits.jpeg"
import img02 from "../../assets/images/liner-shapes/dubai.jpeg"
import img03 from "../../assets/images/liner-shapes/flat-black-kidney.jpeg"
import img04 from "../../assets/images/liner-shapes/gold-coast.jpeg"
import img05 from "../../assets/images/liner-shapes/grecian.jpeg"
import img06 from "../../assets/images/liner-shapes/hampton.jpeg"
import img07 from "../../assets/images/liner-shapes/kidney.jpeg"
import img08 from "../../assets/images/liner-shapes/lagoon.jpeg"
import img09 from "../../assets/images/liner-shapes/lap.jpeg"
import img10 from "../../assets/images/liner-shapes/lazy-l.jpeg"
import img11 from "../../assets/images/liner-shapes/mountain-lake.jpeg"
import img12 from "../../assets/images/liner-shapes/mountain-loch.jpeg"
import img13 from "../../assets/images/liner-shapes/oasis.jpeg"
import img14 from "../../assets/images/liner-shapes/oval.jpeg"
import img15 from "../../assets/images/liner-shapes/rectangle.jpeg"
import img16 from "../../assets/images/liner-shapes/roman-end.jpeg"
import img17 from "../../assets/images/liner-shapes/round.jpeg"
import img18 from "../../assets/images/liner-shapes/semi-inground-pools.jpeg"
import img19 from "../../assets/images/liner-shapes/small-plunge-pool.jpeg"
import img20 from "../../assets/images/liner-shapes/tee.jpeg"
import img21 from "../../assets/images/liner-shapes/true-l.jpeg"

import color01 from "../../assets/images/pool-colors/argos-blue.jpeg"
import color02 from "../../assets/images/pool-colors/bayview-slate-verona.jpeg"
import color03 from "../../assets/images/pool-colors/bayview-slate.jpeg"
import color04 from "../../assets/images/pool-colors/beach-house.jpeg"
import color05 from "../../assets/images/pool-colors/blue-diamond-deco-seaglass.jpeg"
import color06 from "../../assets/images/pool-colors/breton-braid.jpeg"
import color07 from "../../assets/images/pool-colors/butterfly-effect.jpeg"
import color08 from "../../assets/images/pool-colors/cambridge-aquarius.jpeg"
import color09 from "../../assets/images/pool-colors/collage-mosaic.jpeg"
import color10 from "../../assets/images/pool-colors/covington.jpeg"
import color11 from "../../assets/images/pool-colors/dark-blue-diamond.jpeg"
import color12 from "../../assets/images/pool-colors/dark-ocean-blue.jpeg"
import color13 from "../../assets/images/pool-colors/grand-carnival.jpeg"
import color14 from "../../assets/images/pool-colors/hd-electric-blue-seawall.jpeg"
import color15 from "../../assets/images/pool-colors/hd-electric-oxford.jpeg"
import color16 from "../../assets/images/pool-colors/insignia-hd-aquashift.jpeg"
import color17 from "../../assets/images/pool-colors/mayan.jpeg"
import color18 from "../../assets/images/pool-colors/mosaic-verona.jpeg"
import color19 from "../../assets/images/pool-colors/ocean-pearl-all-over.jpeg"
import color20 from "../../assets/images/pool-colors/rustic-diamond.jpeg"
import color21 from "../../assets/images/pool-colors/samara-no-border.jpeg"
import color22 from "../../assets/images/pool-colors/seaglass-all-over.jpeg"
import color23 from "../../assets/images/pool-colors/seaglass-blue-all-over.jpeg"
import color24 from "../../assets/images/pool-colors/siesta-wave-tan.jpeg"
import color25 from "../../assets/images/pool-colors/siesta-wave-verona.jpeg"
import color26 from "../../assets/images/pool-colors/skyline-mosaic-all-over.jpeg"
import color27 from "../../assets/images/pool-colors/skyline-mosaic-electric-gold.jpeg"
import color28 from "../../assets/images/pool-colors/smara.jpeg"
import color29 from "../../assets/images/pool-colors/stacked-marble.jpeg"
import color30 from "../../assets/images/pool-colors/stone-seaglass-verona.jpeg"
import color31 from "../../assets/images/pool-colors/summer-blue-river.jpeg"
import color32 from "../../assets/images/pool-colors/sunburst-oyster-bay.jpeg"
import color33 from "../../assets/images/pool-colors/tropical-bloom.jpeg"
import color34 from "../../assets/images/pool-colors/verona-seaglass-all-over.jpeg"
import color35 from "../../assets/images/pool-colors/wave-ocean-pearl.jpeg"
import SpecialHeader from "../SpecialHeader/SpecialHeader"

type ImageGalleryProps = {
	showModal: boolean
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>
	title: string
	gallery: "shapes" | "colors"
}

const ImageGallery = ({
	showModal,
	setShowModal,
	title,
	gallery,
}: ImageGalleryProps) => {
	const shapeImages = [
		{ src: img01, title: "Auto Cover Kits" },
		{ src: img02, title: "Dubai" },
		{ src: img03, title: "Flat Black Kidney" },
		{ src: img04, title: "Gold Coast" },
		{ src: img05, title: "Grecian" },
		{ src: img06, title: "Hampton" },
		{ src: img07, title: "Kidney" },
		{ src: img08, title: "Lagoon" },
		{ src: img09, title: "Lap" },
		{ src: img10, title: "Lazy L" },
		{ src: img11, title: "Mountain Lake" },
		{ src: img12, title: "Mountain Loch" },
		{ src: img13, title: "Oasis" },
		{ src: img14, title: "Oval" },
		{ src: img15, title: "Rectangle" },
		{ src: img16, title: "Roman End" },
		{ src: img17, title: "Round" },
		{ src: img18, title: "Semi-Inground Pools" },
		{ src: img19, title: "Small Plunge Pools" },
		{ src: img20, title: "Tee" },
		{ src: img21, title: "True L" },
	]

	const colorImages = [
		{ src: color01, title: "Argos Blue" },
		{ src: color02, title: "Bayview Slate Verona" },
		{ src: color03, title: "Bayview Slate" },
		{ src: color04, title: "Beach House" },
		{ src: color05, title: "Blue Diamond Deco Seaglass" },
		{ src: color06, title: "Breton Braid" },
		{ src: color07, title: "Butterfly Effect" },
		{ src: color08, title: "Cambridge Aquarius" },
		{ src: color09, title: "Collage Mosaic" },
		{ src: color10, title: "Covington" },
		{ src: color11, title: "Dark Blue Diamond" },
		{ src: color12, title: "Dark Ocean Blue" },
		{ src: color13, title: "Grand Carnival" },
		{ src: color14, title: "HD Electric Blue Seawall" },
		{ src: color15, title: "HD Electric Oxford" },
		{ src: color16, title: "Insignia HD Aquashift" },
		{ src: color17, title: "Mayan" },
		{ src: color18, title: "Mosaic Verona" },
		{ src: color19, title: "Ocean Pearl All Over" },
		{ src: color20, title: "Rustic Diamond" },
		{ src: color21, title: "Samra No Border" },
		{ src: color22, title: "Seaglass All Over" },
		{ src: color23, title: "Seaglass Blue All Over" },
		{ src: color24, title: "Siesta Wave Tan" },
		{ src: color25, title: "Siesta Wave Verona" },
		{ src: color26, title: "Skyline Mosaic All Over" },
		{ src: color27, title: "Skyline Mosaic Electric Gold" },
		{ src: color28, title: "Samra" },
		{ src: color29, title: "Stacked Marble" },
		{ src: color30, title: "Stone Seaglass Verona" },
		{ src: color31, title: "Summer Blue River" },
		{ src: color32, title: "Sunburst Oyster Bay" },
		{ src: color33, title: "Tropical Bloom" },
		{ src: color34, title: "Verona Seaglass All Over" },
		{ src: color35, title: "Wave Ocean Pearl" },
	]

	return (
		<>
			{showModal && gallery === "shapes" ? (
				<div style={styles.container}>
					<div style={styles.top}>
						<h1 style={bigHeader}>{title}</h1>
						<div style={styles.close} onClick={() => setShowModal(false)}>
							<SpecialHeader headerText="Close" paddingBottom={0} />
						</div>
					</div>
					<div style={styles.imageModal}>
						{shapeImages.map((image, index) => (
							<div key={`${image.title}${index}`}>
								<div style={styles.imageTitle}>{image.title}</div>
								<img
									src={image.src}
									alt={`${image.title}`}
									style={styles.image}
								/>
							</div>
						))}
					</div>
					<Button onClick={() => setShowModal(false)} text="Close" />
				</div>
			) : showModal && gallery === "colors" ? (
				<div style={styles.container}>
					<div style={styles.top}>
						<h1 style={bigHeader}>{title}</h1>
						<div style={styles.close} onClick={() => setShowModal(false)}>
							<SpecialHeader headerText="Close" paddingBottom={0} />
						</div>
					</div>
					<div style={styles.imageModal}>
						{colorImages.map((image, index) => (
							<div style={styles.imageSection} key={`${image.title}${index}`}>
								<div style={styles.imageTitle}>{image.title}</div>
								<img
									src={image.src}
									alt={`${image.title}`}
									style={styles.colorImage}
								/>
							</div>
						))}
					</div>
					<Button onClick={() => setShowModal(false)} text="Close" />
				</div>
			) : null}
		</>
	)
}

const styles: StyleObject = {
	top: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: "30px",
		justifyContent: "space-between",
		width: "100%",
	},
	close: {
		padding: "30px",
		cursor: "pointer",
	},
	container: {
		position: "fixed",
		backgroundColor: colors.white,
		top: 0,
		width: "100%",
		height: "100%",
		overflow: "scroll",
		padding: "20px 20px 40px 20px",
		display: "flex",
		flexDirection: "column",
		zIndex: 100,
		alignSelf: "center",
		alignItems: "center",
	},
	image: {
		maxWidth: "400px",
		width: "100%",
	},
	imageSection: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		gap: "16px",
	},
	colorImage: {
		width: "200px",
		height: "200px",
		borderRadius: "100px",
	},
	imageTitle: {
		textAlign: "center",
		fontSize: 21,
		fontWeight: weight.medium,
	},
	imageModal: {
		backgroundColor: colors.white,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		gap: 40,
		padding: "40px 40px",
		marginBottom: "40px",
	},
}

export default ImageGallery
