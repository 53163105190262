import { StyleObject } from "../../types/Types"
import { colors, weight } from "../../Styles"

type ImageSectionProps = {
	image: string
	bigHeader: string
	smallHeader: string
}

export const ImageSection = ({
	image,
	bigHeader,
	smallHeader,
}: ImageSectionProps) => {
	return (
		<div
			style={{
				...styles.header,
				...{
					backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${image})`,
				},
			}}
		>
			<span style={styles.tagline}>{bigHeader}</span>
			<span style={styles.description}>{smallHeader}</span>
		</div>
	)
}

const styles: StyleObject = {
	header: {
		height: 320,
		width: "100%",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		gap: 20,
		marginTop: 80,
	},
	brandLogo: {
		height: 70,
	},
	tagline: {
		fontSize: 48,
		fontFamily: "Bebas Neue",
		color: colors.white,
		textAlign: "center",
		textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)",
	},
	description: {
		fontSize: 24,
		color: colors.white,
		fontWeight: weight.semiBold,
		textAlign: "center",
		textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)",
	},
}

export default ImageSection
