import { colors, size, weight } from "../../Styles"
import { StyleObject } from "../../types/Types"

type SpecialHeaderProps = {
	headerText: string
	paddingBottom?: number
	color?: string
}

export const SpecialHeader = ({
	headerText,
	paddingBottom = 30,
	color = colors.primary,
}: SpecialHeaderProps) => {
	return (
		<div style={{ ...styles.container, paddingBottom }}>
			<span
				style={{
					...styles.text,
					...{ color: color, borderBottom: `2px solid ${color}` },
				}}
			>
				{headerText}
			</span>
		</div>
	)
}

const styles: StyleObject = {
	text: {
		textTransform: "uppercase",
		fontSize: size.medium,
		padding: "0 0 8px 0",
		fontWeight: weight.semiBold,
		display: "inline-block",
	},
	container: {
		flexDirection: "column",
		maxWidth: "max-content",
	},
}

export default SpecialHeader
