import { StyleObject } from "../../types/Types"
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={styles.container}>
            <h1>ONLINE PRIVACY POLICY AGREEMENT</h1>
            <p style={styles.paragraph}>April 04, 2024</p>
            <p style={styles.paragraph}>The Pool Store Of Indiana values its users' privacy. This Privacy Policy
                will help you understand how we collect and use personal information
                from those who visit our website or make use of our online facilities and
                services, and what we will and will not do with the information we
                collect. Our Policy has been designed and created to ensure those
                affiliated with The Pool Store Of Indiana of our commitment and
                realization of our obligation not only to meet, but to exceed, most
                existing privacy standards.</p>
            <p style={styles.paragraph}>We reserve the right to make changes to this Policy at any given time. If
                you want to make sure that you are up to date with the latest changes, we
                advise you to frequently visit this page. If at any point in time The Pool
                Store Of Indiana decides to make use of any personally identifiable
                information on file, in a manner vastly different from that which was
                stated when this information was initially collected, the user or users
                shall be promptly notified by email. Users at that time shall have the
                option as to whether to permit the use of their information in this
                separate manner.</p>
            <p style={styles.paragraph}>This Policy applies to The Pool Store Of Indiana, and it governs any and
                all data collection and usage by us. Through the use of poolstoreco.com,
                you are therefore consenting to the data collection procedures expressed
                in this Policy.</p>
            <p style={styles.paragraph}>Please note that this Policy does not govern the collection and use of
                information by companies that The Pool Store Of Indiana does not
                control, nor by individuals not employed or managed by us. If you visit a
                website that we mention or link to, be sure to review its privacy policy
                before providing the site with information. It is highly recommended and
                suggested that you review the privacy policies and statements of any
                website you choose to use or frequent to better understand the way in
                which websites garner, make use of and share the information collected.</p>
            <p style={styles.paragraph}>Specifically, this Policy will inform you of the following</p>
            <ol>
                <li>What personally identifiable information is collected from you
                    through our website;</li>
                <li>Why we collect personally identifiable information and the legal
                    basis for such collection;</li>
                <li>How we use the collected information and with whom it may be
                    shared;</li>
                <li>What choices are available to you regarding the use of your data;
                    and</li>
                <li>The security procedures in place to protect the misuse of your
                    information.</li>
            </ol>
            <br></br>
            <h2>Information We Collect</h2>
            <p style={styles.paragraph}>It is always up to you whether to disclose personally identifiable
                information to us, although if you elect not to do so, we reserve the right
                not to register you as a user or provide you with any products or
                services. This website collects various types of information, such as:</p>
            <p style={styles.paragraph}>Voluntarily provided information which may include your name,
                address, email address, billing and/or credit card information etc. which
                may be used when you purchase products and/or services and to deliver
                the services you have requested.</p>
            <p style={styles.paragraph}>Please rest assured that this site will only collect personal information
                that you knowingly and willingly provide to us by way of completed
                membership forms and emails. It is the intent of this site to use personal
                information only for the purpose for which it was requested, and any
                additional uses specifically provided for on this Policy.</p>
            <h2>Why We Collect Information and For How Long</h2>
            <p style={styles.paragraph}>We are collecting your data for several reasons:</p>
            <ul>
                <li>To better understand your needs and provide you with the services
                    you have requested;</li>
                <li>To fulfill our legitimate interest in improving our services and
                    products;</li>
                <li>The data we collect from you will be stored for no longer than
                    necessary. The length of time we retain said information will be
                    determined based upon the following criteria: the length of time
                    your personal information remains relevant; the length of time it is
                    reasonable to keep records to demonstrate that we have fulfilled
                    our duties and obligations; any limitation periods within which
                    claims might be made; any retention periods prescribed by law or
                    recommended by regulators, professional bodies or associations;
                    the type of contract we have with you, the existence of your
                    consent, and our legitimate interest in keeping such information as
                    stated in this Policy.</li>
            </ul>
            <br></br>
            <h2>Use of Information Collected</h2>
            <p style={styles.paragraph}>The Pool Store Of Indiana does not now, nor will it in the future, sell,
                rent or lease any of its customer lists and/or names to any third parties.
                The Pool Store Of Indiana may collect and may make use of personal
                information to assist in the operation of our website and to ensure
                delivery of the services you need and request. At times, we may find it
                necessary to use personally identifiable information as a means to keep
                you informed of other possible products and/or services that may be
                available to you from poolstoreco.com.</p>
            <p style={styles.paragraph}>The Pool Store Of Indiana uses various third-party social media features
                including but not limited to Facebook, Instagram and other interactive
                programs. These may collect your IP address and require cookies to
                work properly. These services are governed by the privacy policies of
                the providers and are not within The Pool Store Of Indiana's control.</p>
            <h2>Disclosure of Information</h2>
            <p style={styles.paragraph}>The Pool Store Of Indiana may not use or disclose the information
                provided by you except under the following circumstances:</p>
            <ul>
                <li>as necessary to provide services or products you have ordered;</li>
                <li>as required by law, or in response to a subpoena or search warrant;</li>
                <li>To outside auditors who have agreed to keep the information
                    confidential;</li>
                <li>as necessary to enforce the Terms of Service;</li>
                <li>as necessary to maintain, safeguard and preserve all the rights and
                    property of The Pool Store Of Indiana.</li>
            </ul>
            <br></br>
            <h2>Non-Marketing Purposes</h2>
            <p style={styles.paragraph}>The Pool Store Of Indiana greatly respects your privacy. We do maintain
                and reserve the right to contact you if needed for non-marketing
                purposes (such as bug alerts, security breaches, account issues, and/or
                changes in The Pool Store Of Indiana products and services). In certain
                circumstances, we may use our website, newspapers, or other public
                means to post a notice.</p>
            <h2>Children under the age of 18</h2>
            <p style={styles.paragraph}>The Pool Store Of Indiana's website is not directed to, and does not
                knowingly collect personal identifiable information from, children under
                the age of eighteen (18). If it is determined that such information has
                been inadvertently collected on anyone under the age of eighteen (18),
                we shall immediately take the necessary steps to ensure that such
                information is deleted from our system's database, or in the alternative,
                that verifiable parental consent is obtained for the use and storage of
                such information. Anyone under the age of eighteen (18) must seek and
                obtain parent or guardian permission to use this website.</p>
            <h2>Unsubscribe or Opt-Out</h2>
            <p style={styles.paragraph}>All users and visitors to our website have the option to discontinue
                receiving communications from us by way of email. To discontinue or
                unsubscribe from our website please send an email that you wish to
                unsubscribe to <a href="mailto:kenz.harness@poolstoreco.com">kenz.harness@poolstoreco.com</a>. If you wish to
                unsubscribe or opt-out from any third-party websites, you must go to
                that specific website to unsubscribe or opt-out. The Pool Store Of
                Indiana will continue to adhere to this Policy with respect to any
                personal information previously collected.</p>
            <h2>Links to Other Websites</h2>
            <p style={styles.paragraph}>Our website does contain links to affiliate and other websites. The Pool
                Store Of Indiana does not claim nor accept responsibility for any privacy
                policies, practices and/or procedures of other such websites. Therefore,
                we encourage all users and visitors to be aware when they leave our
                website and to read the privacy statements of every website that collects
                personally identifiable information. This Privacy Policy Agreement
                applies only and solely to the information collected by our website.</p>
            <h2>How to Contact Us</h2>
            <p style={styles.paragraph}>If you have any questions or concerns regarding the Privacy Policy
                Agreement related to our website, please feel free to contact us at the
                following email, telephone number or mailing address.</p>
            <p style={styles.paragraph}>Email: <a href="mailto:kenz.harness@poolstoreco.com">kenz.harness@poolstoreco.com</a></p>
            <p style={styles.paragraph}>Telephone Number: 260-213-1600</p>
            <p style={styles.paragraph}>Mailing Address:</p>
            <address>
                The Pool Store Of Indiana
                2931 E 1425 N
                North Manchester, Indiana 46962
            </address>
        </div>
    );
}

export default PrivacyPolicy;

const styles: StyleObject = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "50px 0px",
        width: "66.66%", // 2/3rds of the screen
        margin: "0 auto", // centers the container
    },
    paragraph: {
        marginBottom: "20px", // adds space below each paragraph
    }
}