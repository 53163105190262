import { colors } from "../../Styles"
import { StyleObject } from "../../types/Types"

type MobileIconProps = {
	onClick: () => void
}

export const MobileIcon = ({ onClick }: MobileIconProps) => {
	return (
		<button style={styles.anchor} onClick={onClick}>
			<div style={styles.container}>
				<div style={styles.line} />
				<div style={styles.line} />
				<div style={styles.line} />
			</div>
		</button>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-evenly",
		borderRadius: "50%",
		alignItems: "center",
		height: 40,
		width: 40,
		fontSize: 0,
		borderWidth: 2,
		borderStyle: "solid",
		borderColor: colors.white,
		padding: "7px 0",
	},
	line: {
		width: 20,
		height: 2,
		backgroundColor: colors.white,
	},
	anchor: {
		cursor: "pointer",
		backgroundColor: "transparent",
		border: "none",
	},
}

export default MobileIcon
