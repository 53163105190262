import { Helmet } from 'react-helmet';
import ImageHeader from "../../components/ImageHeader/ImageHeader";
import { colors, weight } from "../../Styles";
import { StyleObject } from "../../types/Types";
import SpecialHeader from "../../components/SpecialHeader/SpecialHeader"
import HeaderImage from "../../assets/images/pool-10.webp";


const FinancingPage = () => {
    return (
        <div style={styles.container}>
            <Helmet>
                <title>The Pool Store | Financing</title>
                <meta name="robots" content="index,follow" />
            </Helmet>
            <ImageHeader
                tagline="Financing Your Dream Pool"
                description="Make a Splash with Affordable Financing Options"
                logo={false}
                specialHeaderText=""
                specialHeaderColor={colors.white}
                specialHeaderBottom={10}
                image={HeaderImage}
            />
            <div style={styles.quoteContainer}>
                <div style={styles.quoteContent}>
                    <SpecialHeader headerText="Easy and Flexible Financing" />
                    <span style={styles.quoteText}>
                        We've partnered with <strong>Lyon Financial</strong> to offer you:
                    </span>
                    <ul>
                        <li><strong>Competitive Rates</strong>: Enjoy low rates and flexible terms tailored to your financial situation.</li>
                        <li><strong>Whole-Project Funding</strong>: Not just for the pool, but for the entire backyard transformation.</li>
                        <li><strong>No Fees</strong>: No hidden charges, no prepayment penalties, and no home equity requirements.</li>
                        <li><strong>Fast Funding</strong>: Quick application process with same-day funding available.</li>
                    </ul>
                </div>
            </div>
            <div style={styles.quoteContainer}>
                <div style={styles.quoteContent}>
                    <SpecialHeader headerText="How It Works" />
                    <ol>
                        <li><strong>Explore Your Options</strong>: Use our financing calculator to estimate your monthly payments.</li>
                        <li><strong>Get Pre-Qualified</strong>: Click on the link below to visit Lyon Financial and fill out a simple form to see if you pre-qualify without affecting your credit score.</li>
                        <li><strong>Finalize Your Loan</strong>: Once pre-qualified, Lyon Financial will work with you to finalize the loan details.</li>
                    </ol>
                    <p><a href="https://www.lyonfinancial.net/dealer/the-pool-store-of-indiana/" target="_blank" rel="noopener noreferrer">Get Pre-Qualified Now</a></p>
                    <p><em>Subject to credit approval. Terms and conditions apply.</em></p>
                </div>
            </div>
        </div>
    );
};

const styles: StyleObject = {
    container: {
        display: "flex",
        flexDirection: "column",
    },
    quoteContainer: {
        width: "100%",
        padding: "50px 20px",
        backgroundColor: colors.white,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 60,
    },
    quoteContent: {
        maxWidth: 800,
        padding: "0 20px",
    },
    quoteText: {
        color: colors.black,
        fontFamily: "Montserrat",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: weight.light,
        lineHeight: "170%",
        marginBottom: 20,
        display: "inline-block",
    },
};

export default FinancingPage;
