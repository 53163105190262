import { StyleObject } from "../../types/Types"
import ChevronLeft from "../../assets/icons/chevron-left.svg"
import ChevronRight from "../../assets/icons/chevron-right.svg"
import Carousel, { CarouselProps } from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./ImageCarousel.css"

const responsive: CarouselProps["responsive"] = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		partialVisibilityGutter: 40,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		partialVisibilityGutter: 30,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		partialVisibilityGutter: 30,
	},
}

type ImageCarouselProps = {
	images: string[]
}

export const ImageCarousel = ({ images }: ImageCarouselProps) => {
	const renderNav = (direction: "left" | "right") => {
		return (
			<div
				className="arrow"
				style={{
					...styles.navItem,
					...(direction === "right" ? { right: 20 } : { left: 20 }),
				}}
			>
				<div style={styles.navImageContainer}>
					<img
						src={direction === "right" ? ChevronRight : ChevronLeft}
						style={{ width: "100%", userSelect: "none" }}
						alt="Navigation Chevron"
						loading="lazy"
					/>
				</div>
			</div>
		)
	}
	return (
		<Carousel
			removeArrowOnDeviceType={["mobile", "tablet"]}
			customRightArrow={renderNav("right")}
			customLeftArrow={renderNav("left")}
			className="image-carousel"
			responsive={responsive}
			rtl={false}
			partialVisible
			swipeable
			autoPlay
			infinite
			arrows
		>
			{images.map((image, index) => (
				<div key={index} style={styles.imageContainer}>
					<img
						src={image}
						style={styles.image}
						alt={`Work Preview ${index}`}
						loading="lazy"
					/>
				</div>
			))}
		</Carousel>
	)
}

const styles: StyleObject = {
	imageContainer: {
		maxWidth: 600,
		maxHeight: 400,
		width: "100%",
		height: "100%",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		userSelect: "none",
	},
	image: {
		maxWidth: "100%",
		maxHeight: "100%",
		width: "auto",
		height: "100%",
		objectFit: "cover",
		userSelect: "none",
	},
	navItem: {
		top: "50%",
		position: "absolute",
		transform: "translateY(-50%)",
		padding: "10px 10px",
		backgroundColor: "rgba(23, 181, 229, 0.5)",
		cursor: "pointer",
		zIndex: 1,
		userSelect: "none",
	},
	navImageContainer: {
		height: 40,
		width: 40,
		display: "flex",
		flex: 1,
		userSelect: "none",
	},
}

export default ImageCarousel
