import { StyleObject } from "../../types/Types"
import HeaderImage from "../../assets/images/pool-10.webp"
import LogoWhite from "../../assets/logos/logo-white.png"
import { colors, weight } from "../../Styles"
import SpecialHeader from "../SpecialHeader/SpecialHeader"

type ImageHeaderProps = {
	logo: boolean
	tagline: string
	description: string
	specialHeaderText?: string
	specialHeaderColor?: string
	specialHeaderBottom?: number
	simple?: boolean
	image?: string
}

export const ImageHeader = ({
	logo,
	tagline,
	description,
	specialHeaderText,
	specialHeaderColor,
	specialHeaderBottom,
	simple,
	image,
}: ImageHeaderProps) => {
	return (
		<div
			style={{
				...styles.header,
				...{
					backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${
						image ?? HeaderImage
					})`,
				},
			}}
		>
			{logo && !simple ? (
				<img
					src={LogoWhite}
					alt="The Pool Store Logo"
					height={70}
					loading="eager"
				/>
			) : null}
			{specialHeaderText && !simple ? (
				<SpecialHeader
					headerText={specialHeaderText}
					color={specialHeaderColor}
					paddingBottom={specialHeaderBottom}
				/>
			) : null}
			{!simple && (
				<span style={styles.tagline} className="tagline">
					{tagline}
				</span>
			)}
			{!simple && (
				<span style={styles.description} className="header-description">
					{description}
				</span>
			)}
			{simple && specialHeaderText ? (
				<div style={styles.simpleContainer}>
					<div style={styles.simpleText}>{specialHeaderText}</div>
				</div>
			) : null}
		</div>
	)
}

const styles: StyleObject = {
	header: {
		height: 390,
		width: "100%",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		gap: 20,
		position: "relative",
		padding: "0 20px",
	},
	simpleContainer: {
		maxWidth: 1200,
		position: "relative",
		height: "100%",
		width: "100%",
	},
	simpleText: {
		position: "absolute",
		bottom: 0,
		left: 0,
		backgroundColor: colors.primary,
		color: colors.white,
		textTransform: "uppercase",
		fontSize: 48,
		fontWeight: weight.regular,
		fontFamily: "Bebas Neue",
		width: 280,
		height: 60,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	tagline: {
		fontSize: 48,
		fontFamily: "Bebas Neue",
		color: colors.white,
		textAlign: "center",
		textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)",
	},
	description: {
		fontSize: 24,
		color: colors.white,
		fontWeight: weight.semiBold,
		maxWidth: 600,
		textAlign: "center",
		textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)",
	},
}

export default ImageHeader
