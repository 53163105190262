import { StyleObject } from "../../types/Types"
import { useNavigate } from "react-router-dom"
import { weight } from "../../Styles"
import { Helmet } from 'react-helmet'
import InstagramCard from "../../components/InstagramCard/InstagramCard"
import SpecialHeader from "../../components/SpecialHeader/SpecialHeader"
import Testimonial from "../../components/Testimonial/Testimonial"
import ImageSection from "../../components/ImageSection/ImageSection"
import HardscapeImage from "../../assets/images/pool-8.webp"
import ServicesImage from "../../assets/images/pool-9.webp"
import Button from "../../components/Button/Button"

export const HomePage = () => {
	const navigate = useNavigate()

	return (
		<div style={styles.container}>
			<Helmet>
				<title>The Pool Store | Home</title>
				<meta name="description" content="The Pool Store is Northern Indiana's largest fiberglass pool dealer. We offer unparalleled communication and service with our customers." />
                <meta name="robots" content="index,follow" />
            </Helmet>
			<section style={styles.section}>
				<SpecialHeader headerText="Who We Are" />
				<span style={styles.bigHeader}>
					Northern Indiana's Largest Fiberglass Pool Dealer
				</span>
				<span style={styles.smallHeader}>
					Offering unparalleled communication and service with our customers.
				</span>
				<span style={styles.reading}>
					At The Pool Store, we are a leading pool and hardscaping company
					dedicated to creating stunning outdoor spaces. With a team of skilled
					professionals, we bring years of experience and passion to every
					project. From custom pools to beautiful landscapes, we prioritize
					exceptional service and craftsmanship. We listen to our clients'
					unique needs and combine them with our expertise to deliver
					personalized designs. With a focus on quality and customer
					satisfaction, we strive to exceed expectations and create outdoor
					environments that inspire and delight.
				</span>
			</section>
			<section style={{ ...styles.section, ...styles.testimonialSection }}>
				<SpecialHeader headerText="Testimonials" />
				<div style={styles.testimonials} className="scrollable-content">
					<Testimonial
						name={"The Krug Family"}
						stars={5}
						customerType={"pool"}
						review={
							"The installation process was quick and professional. The gentleman doing the installation were a pleasure to talk to and always had a smile on their face."
						}
					/>
					<Testimonial
						name={"Ian P."}
						stars={5}
						customerType={"pool"}
						review={
							"The Pool Store boys will give you the top rated experience you deserve with your pool install."
						}
					/>
					<Testimonial
						name={"Kristi R."}
						stars={5}
						customerType={"pool"}
						review={
							"We love our pool from The Pool Store!! Best decision ever! We use our pool so much and it's very easy to maintain."
						}
					/>
					<Testimonial
						name={"Jared M."}
						stars={5}
						customerType={"pool"}
						review={
							"Awesome to work with, fair, and great communication throughout the entire installation process."
						}
					/>
				</div>
			</section>
			{/* <section
				style={{
					...styles.section,
					...styles.instagramSection,
				}}
			>
				<SpecialHeader headerText="Latest Projects" />
				<div style={styles.instagramSlider} className="scrollable-content">
					{posts.map((data, index) => (
						<InstagramCard key={index} image={data.media_url} />
					))}
				</div>
			</section> */}
			<ImageSection
				image={ServicesImage}
				bigHeader={"Dive Into Paradise"}
				smallHeader={"Where Dreams Float and Fun Takes the Plunge"}
			/>
			<section style={{ ...styles.section, ...{ marginTop: 50 } }}>
				<SpecialHeader headerText="Pool Services" />
				<span style={styles.bigHeader}>Pool Design & Construction</span>
				<span style={styles.smallHeader}>
					Transforming backyards with stunning custom pools.
				</span>
				<span style={styles.reading}>
					At The Pool Store, we specialize in creating stunning custom pools
					that transform ordinary backyards into captivating retreats. Our
					team's expertise lies in crafting unique and tailored pool designs,
					ensuring each project perfectly fits the client's vision and
					lifestyle. With a focus on quality and customer satisfaction, we take
					pride in delivering exceptional results that exceed expectations.
				</span>
			</section>
			<section style={{ ...styles.section, ...{ marginTop: 50 } }}>
				<span style={styles.bigHeader}>Pool Repairs & Troubleshooting</span>
				<span style={styles.smallHeader}>
					Expert pool repairs, swift solutions, worry-free enjoyment.
				</span>
				<span style={styles.reading}>
					Our pool repair and troubleshooting services are designed to address
					any pool-related issues promptly and effectively. Our skilled
					technicians are equipped to handle a wide range of repairs, from
					fixing pumps and filters to resolving pool equipment malfunctions.
					With a keen eye for detecting problems and implementing reliable
					solutions, we aim to ensure that your pool operates efficiently and
					remains in top-notch condition for endless enjoyment.
				</span>
			</section>
			<Button
				className="button"
				onClick={() => navigate("pools")}
				text="See More"
				style={{ margin: "50px 20px 0 20px", padding: "0 20px 0 20px" }}
			/>
			<ImageSection
				image={HardscapeImage}
				bigHeader={"Hardscape Hideaway Havens"}
				smallHeader={"Transform Your Outdoors into Rockin' Retreats"}
			/>
			<section style={{ ...styles.section, ...{ marginTop: 50 } }}>
				<SpecialHeader headerText="Hardscape Services" />
				<span style={styles.bigHeader}>
					Paver Patios, Fire Pits, and Retaining Walls
				</span>
				<span style={styles.smallHeader}>
					Elevate outdoor living with stunning hardscaping elements.
				</span>
				<span style={styles.reading}>
					Elevate your outdoor living with our exceptional hardscaping services.
					We specialize in crafting stunning paver patios that seamlessly
					integrate with your indoor space, providing a perfect setting for
					relaxation and entertainment. Experience the enchanting allure of our
					fire pits, where the flickering flames foster cozy gatherings and
					cherished memories. Our sturdy retaining walls not only add visual
					appeal but also serve as essential structural elements to protect your
					landscape from erosion and create dynamic multi-level designs. With
					our attention to detail and commitment to quality craftsmanship, we
					create outdoor spaces that captivate the eye and warm the heart.
				</span>
			</section>
			<section style={{ ...styles.section, ...{ marginTop: 50 } }}>
				<span style={styles.bigHeader}>3D Patio and Landscape Design</span>
				<span style={styles.smallHeader}>
					Future visions realized through immersive 3D design.
				</span>
				<span style={styles.reading}>
					Embrace the future of outdoor design with our state-of-the-art 3D
					patio and landscape design services. Visualize your dream patio and
					outdoor haven in stunning detail with our realistic 3D renderings. Our
					cutting-edge software allows you to explore various layout options,
					material choices, and landscaping features, providing you with an
					immersive virtual tour of your future paradise. Be an active part of
					the design process, share your ideas, and make informed decisions that
					reflect your individual style and preferences. Witness your vision
					come to life and experience the magic of our innovative 3D design,
					ensuring a seamless transition from imagination to reality.
				</span>
			</section>
			<section style={{ ...styles.section, ...{ marginTop: 50 } }}>
				<span style={styles.bigHeader}>Landscape Installation</span>
				<span style={styles.smallHeader}>
					Harmonious outdoor beauty, brought to life expertly.{" "}
				</span>
				<span style={styles.reading}>
					Immerse yourself in the art of outdoor transformation with our
					unparalleled landscape installation services. Our expert team
					meticulously plans and executes each project, bringing to life a
					harmonious blend of natural beauty and functional elegance. Whether
					it's creating lush gardens, designing captivating pathways, installing
					water features, or adding vibrant plantings, we tailor each element to
					match your vision and enhance the overall aesthetics of your outdoor
					space. Witness your dreams take shape as we turn your landscape into
					an enchanting sanctuary where tranquility and creativity thrive.
				</span>
			</section>
			<Button
				className="button"
				onClick={() => navigate("hardscape")}
				text="See More"
				style={{ margin: "50px 20px 0 20px", padding: "0 20px 0 20px" }}
			/>
			<div style={styles.spacer} />
		</div>
	)
}

const styles: StyleObject = {
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "50px 0px 0px 0px",
	},
	bigHeader: {
		fontSize: 36,
		fontFamily: "Bebas Neue",
		display: "block",
		textAlign: "center",
		margin: "0 20px",
	},
	smallHeader: {
		fontSize: 20,
		fontWeight: weight.regular,
		display: "block",
		textAlign: "center",
		margin: "10px 20px 0 20px",
	},
	section: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	reading: {
		fontSize: 16,
		fontWeight: weight.light,
		lineHeight: "150%",
		maxWidth: 950,
		padding: "0 20px",
		textAlign: "center",
		marginTop: 20,
	},
	testimonials: {
		marginTop: 10,
		display: "flex",
		flexDirection: "row",
		gap: 40,
		overflowX: "auto",
		whiteSpace: "nowrap",
		paddingTop: 10,
		paddingBottom: 20,
		paddingLeft: 20,
		paddingRight: 20,
		maxWidth: "100%",
		flexShrink: 1,
	},
	instagramSlider: {
		flexDirection: "row",
		display: "flex",
		gap: 50,
		overflowX: "auto",
		whiteSpace: "nowrap",
		maxWidth: "100%",
		paddingBottom: 20,
		paddingRight: 20,
		marginTop: 10,
		paddingLeft: 20,
		flexShrink: 1,
	},
	instagramSection: {
		width: "100%",
		marginTop: 50,
	},
	testimonialSection: {
		width: "100%",
		marginTop: 50,
	},
	spacer: {
		height: 100,
	},
}

export default HomePage
